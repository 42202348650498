import NextHead from 'next/head'

import type { ISbStoryData } from '@storyblok/react'

type HeadProps = {
  story: ISbStoryData
  seo_metatags: ISbStoryData['content']['seo_metatags']
}

export const Head = ({ story, seo_metatags }: HeadProps) => {
  const title = seo_metatags?.title || `${story?.name} | Cercle Brugge KSV`
  const ogTitle = seo_metatags?.og_title || title
  const twitterTitle = seo_metatags?.twitter_title || title
  const description = seo_metatags?.description || ''
  const ogDescription = seo_metatags?.og_description || description
  const twitterDescription = seo_metatags?.twitter_description || description
  // If it's an article, use the banner image as the og_image fallback
  const ogImage =
    seo_metatags?.og_image ||
    story?.content?.thumbnail?.filename ||
    'https://cerclebrugge.be/placeholder.webp'
  const twitterImage = seo_metatags?.twitter_image || ogImage

  return (
    <NextHead>
      <title>{title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" content={description} />

      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      {ogImage && <meta property="og:image" content={ogImage} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={twitterTitle} />
      <meta name="twitter:description" content={twitterDescription} />
      {twitterImage && <meta name="twitter:image" content={twitterImage} />}

      <link rel="icon" href="/favicon.ico" />
    </NextHead>
  )
}
